import { defineStore } from 'pinia'

export const useShortlistStore = defineStore('shortlistStore', () => {

  const shortlist = ref({})

  function addVehicleToShortlist(vehicleId: string, vehicle: Object) {
    
    if (shortlist.value[vehicleId]) {
      delete shortlist.value[vehicleId];
      return;
    }

    // const index = shortlist.value[vehicleId];
    // if (index > -1) {
    //   return false;
    // }

    shortlist.value[vehicleId] = vehicle;

  }

  function vehicleIsInShortlist(vehicleId: string) {
    return shortlist.value[vehicleId] ? true : false;
  }




  function removeVehicle(vehicleId: string, vehicle: Object) {
    
    const index = shortlist.value[vehicleId].indexOf(vehicle);
    if (index > -1) {
      shortlist.value[vehicleId].splice(index, 1);
    }
    
    if (shortlist.value[vehicleId].length < 1) {
      delete shortlist.value[vehicleId];
    }

  }

  function clearShortlist() {
    shortlist.value = {}
  }

  function getCount(){
    return Object.keys(shortlist.value).length;
  }



  return { addVehicleToShortlist, removeVehicle, clearShortlist, getCount, vehicleIsInShortlist, shortlist }
},{
  persist: true,

});